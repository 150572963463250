import { getCompanyConfig } from 'src/application/theming/companyConfigs/helper/getCompanyConfig';
import shng from 'src/application/theming/companyConfigs/SHNG.companyConfig';
import { type CompanyConfigThemeColors } from 'src/application/theming/companyConfigs/types';

const getColors = (): CompanyConfigThemeColors => {
  const defaultColors = shng.theme.colors;
  const companyConfig = getCompanyConfig();
  return companyConfig?.theme.colors ?? defaultColors;
};
export let DSO_THEME_COLORS = getColors();

export const setColors = (colors: CompanyConfigThemeColors): void => {
  DSO_THEME_COLORS = colors;
};
